<template>
	<div>
		<pui-datatable :modelName="model" :modelColumnDefs="columnDefs" :showDeleteBtn="true"></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';

//
//import constants from '@/utils/constants';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmtimezonegrid',
	data() {
		return {
			model: 'pmtimezone',
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			}
		};
	},
	computed: {},
	mounted() {},
	created() {},
	methods: {}
};
</script>

<style lang="postcss" scoped></style>
